import Collapse from 'bootstrap/js/dist/collapse.js';
import Dropdown from 'bootstrap/js/dist/dropdown.js';

(function () {

	// DOMContentLoaded
	//--------------------------------------------------------------------------
	document.addEventListener("DOMContentLoaded", ( e ) => {
		var burger = document.querySelector("header a.burger");
		if (burger) {
			burger.addEventListener("click", (e) => {
				e.preventDefault();
				document.body.classList.add("show-menu");
			});
		}

		var close = document.querySelector("header a.close");
		if (close) {
			close.addEventListener("click", (e) => {
				e.preventDefault();
				document.body.classList.remove("show-menu");
			});
		}

		var preview = document.querySelector("img[data-preview]");
		if (preview) {
			document.querySelectorAll('a[data-toggle="preview"]').forEach((el) => {
				el.addEventListener("click", (e) => {
					e.preventDefault();
					let src = el.getAttribute("data-src");
					if (src) {
						preview.setAttribute("src", src);
					}
				});
			});
		}
	});

})();
